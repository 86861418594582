import React from "react";
import { Box, Typography, Divider, Avatar, IconButton, Button } from "@mui/material";
import { ShoppingCart, Close } from "@mui/icons-material";
import QuantityControl from "./QuantityControl";

const CartSummary = React.memo(
    ({ items, subtotal, deliveryFee, total, updateItemQuantity, removeItem, theme, navigate, getDiscountedPrice }) => (
        <Box sx={{ mb: 3 }}>
            <Typography variant="h5" sx={{ mb: 3, fontWeight: "bold" }}>
                Votre commande
            </Typography>
            {items.length > 0 ? (
                <>

                    <Box sx={{ mb: 3 }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                            <Typography>Sous-total:</Typography>
                            <Typography>{subtotal.toFixed(2)} TND</Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                            <Typography>Livraison:</Typography>
                            <Typography>{deliveryFee.toFixed(2)} TND</Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                mt: 2,
                                pt: 2,
                                borderTop: `1px solid ${theme.palette.divider}`,
                            }}
                        >
                            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                Total:
                            </Typography>
                            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                {total.toFixed(2)} TND
                            </Typography>
                        </Box>
                    </Box>
                </>
            ) : (
                <Box
                    sx={{
                        textAlign: "center",
                        p: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 2,
                    }}
                >
                    <ShoppingCart sx={{ fontSize: 60, color: "text.disabled" }} />
                    <Typography variant="h6" color="text.secondary">
                        Votre panier est vide
                    </Typography>
                    <Button variant="outlined" onClick={() => navigate("/")}>
                        Continuer vos achats
                    </Button>
                </Box>
            )}
        </Box>
    )
);

CartSummary.displayName = "CartSummary";

export default CartSummary;