import React from 'react';
import { Box, Typography, Grid, Modal, IconButton, Divider, Button, Slide } from '@mui/material';
import { useCart } from 'react-use-cart';
import { useDispatch, useSelector } from 'react-redux';
import { closeCart } from "../store/slices/commonConfigSlice";
import { Add, Delete, Remove, Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const CartModal = () => {
    const { items, cartTotal, updateItemQuantity, removeItem, emptyCart } = useCart();
    const isCartOpen = useSelector((state) => state.commonConfig.isCartOpen);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const deliveryFees = 8;
    const subTotal = cartTotal.toFixed(2);
    const total = (cartTotal + deliveryFees).toFixed(2);

    const handleClose = () => {
        dispatch(closeCart());
    };

    const handleOrder = () => {
        navigate("/order");
        handleClose();
    };

    return (
        <Modal
            open={isCartOpen}
            onClose={handleClose}
            aria-labelledby="cart-modal-title"
            aria-describedby="cart-modal-description"
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                p: { xs: 1, md: 2 },
            }}
        >
            <Slide direction="left" in={isCartOpen} timeout={400}>
                <Box
                    sx={{
                        width: { xs: '100%', sm: '80%', md: '450px' },
                        maxWidth: '450px',
                        height: { xs: '100%', md: '90vh' },
                        maxHeight: '90vh',
                        bgcolor: 'white',
                        borderRadius: { xs: 0, md: 3 },
                        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                    }}
                >
                    {/* Header */}
                    <Box
                        sx={{
                            p: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            bgcolor: 'primary.main',
                            color: 'white',
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                            سلة التسوق
                        </Typography>
                        <IconButton onClick={handleClose} sx={{ color: 'white' }}>
                            <Close />
                        </IconButton>
                    </Box>

                    {/* Cart Items */}
                    <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2 }}>
                        {items.length === 0 ? (
                            <Box
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    gap: 2,
                                }}
                            >
                                <Typography variant="h6" color="text.secondary">
                                    سلة التسوق فارغة
                                </Typography>
                                <Button
                                    variant="contained"
                                    onClick={handleClose}
                                    sx={{ borderRadius: 2 }}
                                >
                                    تصفح المنتجات
                                </Button>
                            </Box>
                        ) : (
                            items.map((item) => (
                                <Box
                                    key={item.id}
                                    sx={{
                                        mb: 2,
                                        p: 2,
                                        borderRadius: 2,
                                        bgcolor: 'grey.50',
                                        transition: 'all 0.2s',
                                        '&:hover': { bgcolor: 'grey.100' },
                                    }}
                                >
                                    <Grid container alignItems="center" spacing={1}>
                                        <Grid item xs={8}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                                {item.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                            <Typography variant="body2" sx={{ fontWeight: 700 }}>
                                                {parseInt(item.price) * item.quantity} د.ت
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1,
                                                    mt: 1,
                                                }}
                                            >
                                                <IconButton
                                                    onClick={() => updateItemQuantity(item.id, item.quantity - 1)}
                                                    disabled={item.quantity <= 1}
                                                    sx={{
                                                        bgcolor: 'grey.200',
                                                        '&:hover': { bgcolor: 'grey.300' },
                                                    }}
                                                >
                                                    <Remove fontSize="small" />
                                                </IconButton>
                                                <Typography variant="body2" sx={{ minWidth: 20, textAlign: 'center' }}>
                                                    {item.quantity}
                                                </Typography>
                                                <IconButton
                                                    onClick={() => updateItemQuantity(item.id, item.quantity + 1)}
                                                    sx={{
                                                        bgcolor: 'grey.200',
                                                        '&:hover': { bgcolor: 'grey.300' },
                                                    }}
                                                >
                                                    <Add fontSize="small" />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => removeItem(item.id)}
                                                    sx={{
                                                        ml: 'auto',
                                                        color: 'error.main',
                                                        bgcolor: 'white',
                                                        '&:hover': { bgcolor: 'error.main', color: 'white' },
                                                    }}
                                                >
                                                    <Delete fontSize="small" />
                                                </IconButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))
                        )}
                    </Box>

                    {/* Summary Section */}
                    {items.length > 0 && (
                        <Box
                            sx={{
                                p: 2,
                                borderTop: '1px solid',
                                borderColor: 'grey.200',
                                bgcolor: 'white',
                            }}
                        >
                            <Box sx={{ mb: 2 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                    <Typography variant="body2" color="text.secondary">
                                        المجموع الجزئي
                                    </Typography>
                                    <Typography variant="body2">{subTotal} د.ت</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                    <Typography variant="body2" color="text.secondary">
                                        مصاريف التوصيل
                                    </Typography>
                                    <Typography variant="body2">{deliveryFees} د.ت</Typography>
                                </Box>
                                <Divider sx={{ my: 1 }} />
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                        الإجمالي
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                        {total} د.ت
                                    </Typography>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={emptyCart}
                                    startIcon={<Delete />}
                                    sx={{ flex: 1, borderRadius: 2 }}
                                >
                                    إفراغ
                                </Button>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={handleOrder}
                                    sx={{ flex: 1, borderRadius: 2 }}
                                >
                                    إتمام الطلب
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Slide>
        </Modal>
    );
};

export default CartModal;