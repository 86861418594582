import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

const QuantityControl = React.memo(({ item, updateItemQuantity, theme }) => (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <IconButton
            onClick={() => updateItemQuantity(item.id, item.quantity - 1)}
            disabled={item.quantity === 1}
            size="small"
            sx={{
                backgroundColor: theme.palette.grey[200],
                "&:hover": { backgroundColor: theme.palette.grey[300] },
            }}
        >
            <Remove fontSize="small" />
        </IconButton>
        <Typography>{item.quantity}</Typography>
        <IconButton
            onClick={() => updateItemQuantity(item.id, item.quantity + 1)}
            size="small"
            sx={{
                backgroundColor: theme.palette.grey[200],
                "&:hover": { backgroundColor: theme.palette.grey[300] },
            }}
        >
            <Add fontSize="small" />
        </IconButton>
    </Box>
));

QuantityControl.displayName = "QuantityControl";

export default QuantityControl;