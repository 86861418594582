import React, { lazy, Suspense, useEffect } from "react";
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider
} from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { theme as muiTheme } from "./theme/theme";
import { CartProvider } from "react-use-cart";
import { Provider } from "react-redux";
import store from "./store";
import Layout from "./Layout/Layout";
import { themes } from "./json/memory_game/themes";
import CheckoutPage from "./pages/checkOut";

// Lazy load pages
const HomePageMosaic = lazy(() => import("./pages/Home"));
const MemoryGame = lazy(() => import("./pages/MemoryGame"));
const MemoryCard = lazy(() => import("./Component/card"));
const PlaceOrder = lazy(() => import("./pages/PlaceOrder"));
const Calendar2025 = lazy(() => import("./pages/calendar2025"));
const Calendar2025Price = lazy(() => import("./pages/calendar2025Price"));
const ConfirmOrder = lazy(() => import("./common/confitmOrder")); // ممكن تصلح التسمية لاحقًا
const RamadanNoteBook = lazy(() => import("./pages/RamadanNoteBook"));
const Phrazi = lazy(() => import("./pages/Phrazi"));
const LettersGame = lazy(() => import("./pages/LettersGame"));
const LetterBasicComponent = lazy(() => import("./pages/LetterBasicComponent"));
const WriteErase = lazy(() => import("./pages/WriteErase"));
const MesCartesSyllabes = lazy(() => import("./pages/MesCartesSyllabes"));

function ScrollToTop({ router }) {
    useEffect(() => {
        const unsubscribe = router.subscribe(() => {
            window.scrollTo(0, 0);
        });
        return () => unsubscribe();
    }, [router]);

    return null;
}

function App() {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<Layout />}>
                <Route path="/" element={<HomePageMosaic />} />
                <Route path="/memory-game" element={<MemoryGame />} />
                <Route path="/calendrier-2025" element={<Calendar2025 />} />
                <Route path="/calendrier" element={<Calendar2025Price />} />
                <Route path="/phrazi" element={<Phrazi />} />
                <Route path="/letters-game">
                    <Route path=":language">
                        <Route index element={<LettersGame />} />
                        <Route path=":letter" element={<LetterBasicComponent />} />
                    </Route>
                </Route>
                <Route path="/ramadhan-notebook" element={<RamadanNoteBook />} />
                <Route path="/mes-cartes-syllabes" element={<MesCartesSyllabes />} />
                <Route path="/write-and-erase" element={<WriteErase />} />
                <Route path="/order" element={<CheckoutPage />} />
                <Route path="/confirm-order/:orderId" element={<ConfirmOrder />} />
                {themes.map((theme) => (
                    <Route key={theme.slug} path={`/memory-game/${theme.slug}`}>
                        {theme.cards &&
                            theme.cards.map((card) => (
                                <Route
                                    key={card.name}
                                    path={`/memory-game/${theme.slug}/${card.slug}`}
                                    element={<MemoryCard themeData={theme} cardData={card} />}
                                />
                            ))}
                    </Route>
                ))}
            </Route>
        )
    );

    return (
        <ThemeProvider theme={muiTheme}>
            <Provider store={store}>
                <CartProvider>
                    <Suspense
                        fallback={
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100vh'
                            }}>
                                <img src="/logo.png" alt="جارٍ التحميل..." style={{ width: 80 }} />
                            </div>
                        }
                    >
                        <ScrollToTop router={router} />
                        <RouterProvider router={router} />
                    </Suspense>
                </CartProvider>
            </Provider>
        </ThemeProvider>
    );
}

export default App;
