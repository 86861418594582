import React from "react";
import { Card, CardContent, Box, Typography } from "@mui/material";

const PolicyCard = React.memo(({ icon, title, description }) => (
    <Card sx={{ height: "100%", borderRadius: 3, boxShadow: 3 }}>
        <CardContent sx={{ p: 3, textAlign: "center" }}>
            <Box sx={{ fontSize: 40, mb: 2, color: "primary.main" }}>{icon}</Box>
            <Typography variant="h6" sx={{ mb: 1, fontWeight: "bold" }}>
                {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {description}
            </Typography>
        </CardContent>
    </Card>
));

PolicyCard.displayName = "PolicyCard";

export default PolicyCard;