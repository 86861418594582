import React from "react";
import {
    Box,
    Typography,
    Paper,
    Grid,
    Avatar,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import { CheckCircle, ExpandMore } from "@mui/icons-material";

const CheckoutConfirmation = ({ formik, govs, items, subtotal, deliveryFee, total, theme, getDiscountedPrice }) => (
    <Box sx={{ mb: 4 }}>
        <Box
            sx={{
                p: 3,
                backgroundColor: theme.palette.success.light,
                borderRadius: 3,
                mb: 3,
                display: "flex",
                alignItems: "center",
                gap: 2,
            }}
        >
            <CheckCircle sx={{ fontSize: 40, color: "white" }} />
            <Box sx={{ color : "white"}}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Presque terminé!
                </Typography>
                <Typography>Vérifiez vos informations avant de confirmer la commande</Typography>
            </Box>
        </Box>
        <Typography variant="h5" sx={{ mb: 2, fontWeight: "bold" }}>
            Récapitulatif
        </Typography>
        <Paper sx={{ p: 3, mb: 3, borderRadius: 3 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                Informations client
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2" color="text.secondary">
                        Nom complet
                    </Typography>
                    <Typography>{formik.values.client.fullName}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2" color="text.secondary">
                        Téléphone
                    </Typography>
                    <Typography>{formik.values.client.phone}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary">
                        Adresse
                    </Typography>
                    <Typography>
                        {formik.values.client.address}
                        <br />
                        {(() => {
                            const delegId = formik.values.client.deleg_id;
                            const gov = govs.find((g) => g.delegations.some((d) => d.id === delegId));
                            const deleg = gov?.delegations.find((d) => d.id === delegId);
                            return deleg ? `${deleg.name}, ${gov.name}` : "";
                        })()}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
        <Paper sx={{ p: 3, borderRadius: 3 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                Articles commandés
            </Typography>
            {items.map((item) => (
                <Box
                    key={item.id}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        mb: 2,
                        p: 2,
                        backgroundColor: theme.palette.grey[50],
                        borderRadius: 2,
                    }}
                >
                    <Avatar src={item.image} alt={item.name} sx={{ width: 60, height: 60 }} />
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                            {item.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {getDiscountedPrice(item).toFixed(2)} TND × {item.quantity}
                        </Typography>
                    </Box>
                    <Typography sx={{ fontWeight: "bold" }}>
                        {(getDiscountedPrice(item) * item.quantity).toFixed(2)} TND
                    </Typography>
                </Box>
            ))}
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
                    <Typography>Sous-total:</Typography>
                    <Typography>{subtotal.toFixed(2)} TND</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
                    <Typography>Livraison:</Typography>
                    <Typography>{deliveryFee.toFixed(2)} TND</Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                        backgroundColor: theme.palette.grey[100],
                        borderRadius: 1,
                    }}
                >
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                        Total:
                    </Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                        {total.toFixed(2)} TND
                    </Typography>
                </Box>
            </Box>
        </Paper>
        <Accordion sx={{ mt: 3 }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography sx={{ fontWeight: "bold" }}>Conditions générales</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography paragraph>
                    En confirmant cette commande, vous acceptez nos conditions générales de vente. Les retours sont acceptés dans
                    les 14 jours suivant la réception pour les articles non ouverts et dans leur emballage d'origine.
                </Typography>
                <Typography paragraph>
                    Les frais de livraison sont de 8 TND pour toute la Tunisie. Les délais de livraison estimés sont de 2-3 jours
                    pour le Grand Tunis et 3-5 jours pour les autres régions.
                </Typography>
            </AccordionDetails>
        </Accordion>
    </Box>
);

export default CheckoutConfirmation;