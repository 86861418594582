import React from "react";
import { Box, Typography, Grid, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { Person, Phone, LocationOn } from "@mui/icons-material";

const CheckoutForm = ({ formik, govs, activeGov, filterSelectedGov, formRef, isMobile }) => (
    <Box ref={formRef} sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: "bold" }}>
            Informations de livraison
        </Typography>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label="Nom complet"
                    name="client.fullName"
                    value={formik.values.client.fullName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.client?.fullName && Boolean(formik.errors.client?.fullName)}
                    helperText={formik.touched.client?.fullName && formik.errors.client?.fullName}
                    InputProps={{
                        startAdornment: <Person sx={{ color: "text.secondary", mr: 1 }} />,
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label="Téléphone"
                    name="client.phone"
                    value={formik.values.client.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.client?.phone && Boolean(formik.errors.client?.phone)}
                    helperText={formik.touched.client?.phone && formik.errors.client?.phone}
                    InputProps={{
                        startAdornment: <Phone sx={{ color: "text.secondary", mr: 1 }} />,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Gouvernorat</InputLabel>
                    <Select value={activeGov.gov} onChange={(e) => filterSelectedGov(e.target.value)}>
                        {govs.map((gov) => (
                            <MenuItem key={`gov-${gov.id}`} value={gov.id}>
                                {gov.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Délégation</InputLabel>
                    <Select
                        value={formik.values.client.deleg_id}
                        name="client.deleg_id"
                        onChange={formik.handleChange}
                        error={formik.touched.client?.deleg_id && Boolean(formik.errors.client?.deleg_id)}
                    >
                        {activeGov.delegations.map((delegation) => (
                            <MenuItem key={`delegation-${delegation.id}`} value={delegation.id}>
                                {delegation.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText error>{formik.touched.client?.deleg_id && formik.errors.client?.deleg_id}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Adresse complète"
                    name="client.address"
                    value={formik.values.client.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.client?.address && Boolean(formik.errors.client?.address)}
                    helperText={formik.touched.client?.address && formik.errors.client?.address}
                    multiline
                    rows={3}
                    InputProps={{
                        startAdornment: (
                            <LocationOn sx={{ color: "text.secondary", mr: 1, alignSelf: "flex-start", mt: 1 }} />
                        ),
                    }}
                />
            </Grid>
        </Grid>
    </Box>
);

export default CheckoutForm;