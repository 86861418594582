import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import {
    Box,
    Typography,
    Button,
    Stepper,
    Step,
    StepLabel,
    Badge,
    useMediaQuery,
    useTheme,
    Grid,
    Skeleton,
    CircularProgress,
} from "@mui/material";
import { ShoppingCart, Payment, ArrowBack, LocalShipping, AssignmentReturn, Security, Edit } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useCart } from "react-use-cart";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import moment from "moment";
import axios from "axios";
import { commonApiCall, frontApiCall } from "../api/config";
import QuantityControl from "../Component/order/QuantityControl";
import PolicyCard from "../Component/order/PolicyCard";
import CartSummary from "../Component/order/CartSummary";
import CheckoutForm from "../Component/order/CheckoutForm";
import CheckoutConfirmation from "../Component/order/CheckoutConfirmation";
import CartModal from "../common/CartModal";
import { toggleCart } from "../store/slices/commonConfigSlice";
import { useDispatch } from "react-redux";

const CheckoutPage = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const { items, updateItemQuantity, removeItem, emptyCart } = useCart();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState(0);
    const [govs, setGovs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeGov, setActiveGov] = useState({ gov: "", delegations: [] });
    const [buttonLoading, setButtonLoading] = useState(false);
    const [isConfirmButtonFixed, setIsConfirmButtonFixed] = useState(false); // New state for button position
    const confirmButtonRef = useRef(null); // Ref for the confirm button container
    const formRef = useRef(null);

    const pixelId = "715804273228523";
    ReactPixel.init(pixelId);
    ReactPixel.pageView();

    useEffect(() => {
        const cachedGovs = localStorage.getItem("govs");
        if (cachedGovs) {
            setGovs(JSON.parse(cachedGovs));
            setLoading(false);
            return;
        }
        commonApiCall("")
            .get("/places/govs/all?withDeleg=true")
            .then((res) => {
                setGovs(res.data);
                localStorage.setItem("govs", JSON.stringify(res.data));
            })
            .finally(() => setLoading(false));
    }, []);

    // Intersection Observer to detect when the button's original position is in view
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsConfirmButtonFixed(!entry.isIntersecting);
            },
            {
                root: null,
                threshold: 0.1, // Trigger when 10% of the button is visible
            }
        );

        if (confirmButtonRef.current) {
            observer.observe(confirmButtonRef.current);
        }

        return () => {
            if (confirmButtonRef.current) {
                observer.unobserve(confirmButtonRef.current);
            }
        };
    }, [activeStep]); // Re-run when activeStep changes to re-observe the correct button

    const debouncedTrackEvent = useCallback(
        (eventName, customData) => {
            axios
                .post("https://sghiriwebserver.onrender.com/track-event", {
                    eventName,
                    customData,
                    clientIp: window.location.hostname === "localhost" ? "127.0.0.1" : null,
                    userAgent: navigator.userAgent,
                })
                .catch((error) => console.error("Tracking error:", error));
        },
        []
    );

    const getDiscountedPrice = useCallback((product) => {
        if (!product.promos || !product.reduction || product.reduction.length === 0) return product.price;
        const applicableReduction = product.reduction
            .filter((reduction) => product.quantity >= reduction.quantity)
            .reduce((max, item) => Math.max(max, item.reduction), 0);
        return product.price - (applicableReduction / 100) * product.price;
    }, []);

    const deliveryFee = 8;
    const subtotal = useMemo(() => {
        return items.reduce((acc, item) => acc + item.quantity * getDiscountedPrice(item), 0);
    }, [items, getDiscountedPrice]);
    const total = useMemo(() => Math.round(subtotal + deliveryFee), [subtotal]);

    const formik = useFormik({
        initialValues: {
            client: {
                fullName: "",
                phone: "",
                deleg_id: "",
                address: "",
            },
            items: items,
        },
        enableReinitialize: false,
        validationSchema: Yup.object({
            client: Yup.object({
                fullName: Yup.string().required("Nom complet est requis"),
                phone: Yup.string()
                    .required("Numéro de téléphone est requis")
                    .matches(/^[0-9]{8}$/, "Le numéro de téléphone doit contenir 8 chiffres"),
                address: Yup.string().required("Adresse est requise"),
                deleg_id: Yup.string().required("Délégation est requise"),
            }),
        }),
        onSubmit: (values, { setFieldError }) => {
            const data = new FormData();
            data.append("client[fullName]", values.client.fullName);
            data.append("client[deleg_id]", values.client.deleg_id);
            data.append("client[address]", values.client.address);
            data.append("client[phone]", values.client.phone);
            data.append("order[deliveryDate]", moment().format("YYYY-MM-DD"));
            data.append("order[discount]", 0);
            data.append("order[store_id]", 2);
            data.append("order[packageSize]", 1);
            data.append("order[paymentStatus]", 2);
            data.append("order[descProduit]", "Jeux educatifs");
            data.append("order[free_delivery]", 0);
            data.append("order[amount]", total);
            data.append("order[delivery_price]", 8);
            data.append("order[isExchange]", 0);

            items.forEach((item, index) => {
                data.append(`items[${index}][product_id]`, item.id);
                data.append(`items[${index}][quantity]`, item.quantity);
            });

            frontApiCall("")
                .post("/orders/create", data)
                .then((res) => {
                    debouncedTrackEvent("Purchase", {
                        value: total,
                        currency: "TND",
                        content_ids: items.map((item) => item.id),
                        content_type: "product",
                        contents: items.map((item) => ({
                            id: item.id,
                            quantity: item.quantity,
                            item_price: getDiscountedPrice(item),
                        })),
                    });
                    navigate(`/confirm-order/${res.data.order.id}`);
                    emptyCart();
                })
                .catch((error) => {
                    if (error.response?.status === 400 && error.response.data["client.phone"]) {
                        setFieldError("client.phone", "Le téléphone client est déjà utilisé pour un autre client");
                    }
                })
                .finally(() => setButtonLoading(false));
        },
    });

    const filterSelectedGov = useCallback(
        (id) => {
            formik.setFieldValue("client.deleg_id", "");
            const selectedGov = govs.find((gov) => gov.id === id);
            setActiveGov({ gov: selectedGov.id, delegations: selectedGov.delegations });
        },
        [govs, formik]
    );

    const handleNext = useCallback(async () => {
        setButtonLoading(true);
        if (activeStep === 0) {
            const errors = await formik.validateForm();
            if (Object.keys(errors).length === 0) {
                setActiveStep(1);
                window.scroll(0, 0);
                setButtonLoading(false);
            } else {
                formik.setTouched({
                    client: { fullName: true, phone: true, deleg_id: true, address: true },
                });
                if (formRef.current) {
                    formRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
                }
                setButtonLoading(false);
            }
        } else if (activeStep === 1) {
            formik.handleSubmit();
        }
    }, [activeStep, formik]);

    const handleBack = useCallback(() => {
        setActiveStep((prev) => prev - 1);
    }, []);

    const handleToggleCart = useCallback(() => {
        dispatch(toggleCart());
    }, [dispatch]);

    if (loading) {
        return (
            <Box sx={{ p: 4 }}>
                <Skeleton variant="rectangular" height={60} sx={{ mb: 2 }} />
                <Skeleton variant="rectangular" height={200} sx={{ mb: 2 }} />
                <Skeleton variant="rectangular" height={100} />
            </Box>
        );
    }

    const MotionBox = isMobile ? Box : motion.div;

    return (
        <Box
            sx={{
                minHeight: "100vh",
                background: "linear-gradient(135deg, #f5f7fa 0%, #e4e8ed 100%)",
                p: isMobile ? 2 : 4,
            }}
        >
            <CartModal />
            <Box
                sx={{
                    maxWidth: "100%",
                    mx: "auto",
                    backgroundColor: "white",
                    borderRadius: 4,
                    overflow: "hidden",
                    boxShadow: 3,
                    position: "relative",
                }}
            >
                <Box
                    sx={{
                        p: 3,
                        backgroundColor: theme.palette.success.main,
                        color: "white",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                        Confirmation de la commande
                    </Typography>
                    <Badge
                        badgeContent={items.length}
                        color="secondary"
                        overlap="circular"
                        sx={{
                            "& .MuiBadge-badge": {
                                right: -3,
                                top: 13,
                                border: `2px solid ${theme.palette.primary.main}`,
                                p: "0 4px",
                            },
                        }}
                    >
                        <ShoppingCart sx={{ fontSize: 32 }} />
                    </Badge>
                </Box>

                <Grid container>
                    <Grid item xs={12} md={8} sx={{ p: isMobile ? 2 : 4 }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                            <Stepper activeStep={activeStep} alternativeLabel sx={{ flex: 1 }}>
                                <Step>
                                    <StepLabel>Information</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Confirmation</StepLabel>
                                </Step>
                            </Stepper>
                            <Button
                                variant="outlined"
                                startIcon={<Edit />}
                                onClick={handleToggleCart}
                                sx={{ ml: 2 }}
                            >
                                Modifier le panier
                            </Button>
                        </Box>

                        <AnimatePresence mode="wait">
                            <MotionBox
                                key={activeStep}
                                {...(!isMobile && {
                                    initial: { opacity: 0, x: activeStep === 0 ? -100 : 100 },
                                    animate: { opacity: 1, x: 0 },
                                    exit: { opacity: 0, x: activeStep === 0 ? -100 : 100 },
                                    transition: { duration: 0.3 },
                                })}
                            >
                                {activeStep === 0 ? (
                                    <CheckoutForm
                                        formik={formik}
                                        govs={govs}
                                        activeGov={activeGov}
                                        filterSelectedGov={filterSelectedGov}
                                        formRef={formRef}
                                        isMobile={isMobile}
                                    />
                                ) : (
                                    <CheckoutConfirmation
                                        formik={formik}
                                        govs={govs}
                                        items={items}
                                        subtotal={subtotal}
                                        deliveryFee={deliveryFee}
                                        total={total}
                                        theme={theme}
                                        getDiscountedPrice={getDiscountedPrice}
                                    />
                                )}
                            </MotionBox>
                        </AnimatePresence>

                        <CartSummary
                            items={items}
                            subtotal={subtotal}
                            deliveryFee={deliveryFee}
                            total={total}
                            updateItemQuantity={updateItemQuantity}
                            removeItem={removeItem}
                            theme={theme}
                            navigate={navigate}
                            getDiscountedPrice={getDiscountedPrice}
                        />

                        {/* Button Container with ref */}
                        <Box ref={confirmButtonRef}>
                            {activeStep === 0 ? (
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    onClick={handleNext}
                                    disabled={items.length === 0 || buttonLoading}
                                    color="success"
                                    sx={{
                                        py: 1.5,
                                        fontWeight: "bold",
                                        fontSize: "1.1rem",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                    }}
                                >
                                    {buttonLoading ? <CircularProgress size={24} color="inherit" /> : "Passer à la confirmation"}
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        size="large"
                                        onClick={handleBack}
                                        startIcon={<ArrowBack />}
                                        sx={{ mb: 2, py: 1.5 }}
                                    >
                                        Retour
                                    </Button>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="success"
                                        size="large"
                                        onClick={handleNext}
                                        disabled={buttonLoading}
                                        sx={{
                                            py: 1.5,
                                            fontWeight: "bold",
                                            fontSize: "1.1rem",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                            position: isConfirmButtonFixed ? "fixed" : "static",
                                            bottom: isConfirmButtonFixed ? 20 : "auto",
                                            left: isConfirmButtonFixed ? "50%" : "auto",
                                            transform: isConfirmButtonFixed ? "translateX(-50%)" : "none",
                                            width: isConfirmButtonFixed ? { xs: "90%", md: "50%" } : "100%",
                                            maxWidth: isConfirmButtonFixed ? 600 : "none",
                                            zIndex: 1000,
                                            boxShadow: isConfirmButtonFixed ? "0 4px 12px rgba(0, 0, 0, 0.2)" : "none",
                                            borderRadius: isConfirmButtonFixed ? 2 : 1,
                                        }}
                                    >
                                        {buttonLoading ? <CircularProgress size={24} color="inherit" /> : "Confirmer la commande"}
                                    </Button>
                                </>
                            )}
                        </Box>

                        <Box
                            sx={{
                                mt: 3,
                                p: 2,
                                backgroundColor: "white",
                                borderRadius: 2,
                                textAlign: "center",
                                boxShadow: 1,
                            }}
                        >
                            <Typography variant="body2" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <Payment color="primary" />
                                Paiement sécurisé à la livraison
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={4}
                        sx={{
                            backgroundColor: theme.palette.grey[50],
                            p: isMobile ? 2 : 4,
                            borderLeft: isMobile ? "none" : `1px solid ${theme.palette.divider}`,
                        }}
                    >
                        <Box sx={{ mb: 4 }}>
                            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                                Nos garanties
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={12}>
                                    <PolicyCard
                                        icon={<LocalShipping color="info" />}
                                        title="Livraison rapide"
                                        description="Expédition sous 24h et livraison en 2-5 jours"
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={12}>
                                    <PolicyCard
                                        icon={<AssignmentReturn color="info" />}
                                        title="Retour facile"
                                        description="14 jours pour changer d'avis"
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={12}>
                                    <PolicyCard
                                        icon={<Security color="info" />}
                                        title="Paiement sécurisé"
                                        description="Cryptage SSL pour vos données"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box>
                            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                                Découvrez d'autres produits
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default CheckoutPage;